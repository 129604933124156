import { ref, onMounted, watch, computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'
import useCommon from "@/views/organization/useCommon"
import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import realmConnection from '@/views/habit/realm'

export default function useCustomersOpinions() {
    const { getLocationsForDropDown, locations, formatIsoDate } = useCommon()
    const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const { showSuccessMessage, showErrorMessage } = useNotifications()
    const { router } = useRouter()
    const { getItemsWithAggregate, updateItem, ObjectId } = realmConnection()
    const isOnline = computed(() => store.state.app.isOnline)
    const isLoading = ref(true)
    const opinionId = ref(null)
    const searchQuery = ref('')
    const totalOpinions = ref(0)
    const selectedOpinion = ref('')
    const customersOpinions = ref([])
    const selectedLocations = ref('')
    const stateOpinionOptions = ref(['positive', 'neutral', 'negative'])
    const dataExcel = ref([])
    const userData = store.state?.userStore?.userData;
    const { role } = userData;
    const clientId = userData.role !== "admin" ? userData.client.$oid : null
    const collection = 'customer_opinion'

    const now = new Date()
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1) // Get the first day of the current month
    const [y1, m1, d1] = firstDay.toISOString().substring(0, 10).split('-')
    const reverseFirstDay = `${d1}-${m1}-${y1}`
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0) // Get the last day of the current month
    const [y2, m2, d2] = lastDay.toISOString().substring(0, 10).split('-')
    const reverseLastDay = `${d2}-${m2}-${y2}`
    const dateRange = ref(`${reverseFirstDay} to ${reverseLastDay}`) // Get initial date range

    const perPage = ref(10)
    const currentPage = ref(1)
    const sortBy = ref('date')
    const isSortDirDesc = ref(false)

    const translatedOpinionOptions = computed(() => {
        return stateOpinionOptions.value.map(option => ({
          label: i18n.t(`label.${option}`), 
          value: option
        }))
      })

    const clearFilters = () => {
        dateRange.value = ''
        selectedLocations.value = ''
        searchQuery.value = ''
        selectedOpinion.value = '' 
    }

    const tableColumns = computed(() => {
        return [
            { key: 'date', label: i18n.t('message.tableHeader.date'), sortable: true, thStyle: { width: '10%' } },
            { key: 'location', label: i18n.t('message.tableHeader.location'), sortable: true, thStyle: { width: '10%' } },
            { key: 'feedback', label: i18n.t('message.tableHeader.feedback'), sortable: true, thStyle: { width: '10%' } },
            { key: 'detail', label: i18n.t('message.tableHeader.detail'), sortable: true },
            { key: 'actions', label: i18n.t('message.tableHeader.actions'), thStyle: { width: '15%' } },
        ]
    })

    const listCustomersOpinions = async (component, date, location, searchValue) => {
        if (searchValue && searchValue !== searchQuery.value) return // To avoid early updates of the search when the user is still typing
        
        isLoading.value = true

        const query = {
            client_id: ObjectId(clientId),
            deleted: { $ne: true }
        }

        if (location) {
            query.location = ObjectId(location)
        }

        if (selectedOpinion.value?.value) {
            query.feedback = selectedOpinion.value.value
        }

        if (date) {
            if (component === 'new') {
                const isoActualDate = new Date(date);
                const isoSevenDaysBefore = new Date(date)
        
                isoActualDate.setDate(date.getDate() + 1) // Increment by one day to include the entire current day
                isoSevenDaysBefore.setDate(date.getDate() - 7) // Search data from today to one week ago
                
                query.date = { $gte: isoSevenDaysBefore, $lt: isoActualDate }
            } else if (component === 'list') {
                // Date Range: by default it comes with 'to', but if you click on the same number for the from date and the to date, it arrives without 'to'
                let from, to
                if (date.includes(' to ')) {
                    const datesArray = date.split(" ")
                    from = datesArray[0].split("-")
                    to = datesArray[2].split("-")
                }   else {
                    from = to = date.split("-")
                }
                // Ensure the date is converted to the start and end of the day for accurate filtering
                const fromDate = new Date(parseDate(from))
                const toDate = new Date(parseDate(to))
                toDate.setHours(23, 59, 59, 999)

                query.date = { $gte: fromDate, $lt: toDate }
            }
        }

        const pipeline = [
            { $match: query },
            { $lookup: { from: 'location', localField: 'location', foreignField: '_id', pipeline: [ { $project: { location: 1 } } ], as: 'location' } },
            { $addFields: { _id: { $toString: "$_id" }, location: { $arrayElemAt: ["$location", 0] } } },
            { $sort: { date: 1 } },
        ]

        try {
            let backData = await getItemsWithAggregate({ collection, pipeline })
            
            if (searchQuery.value) {
                backData = backData?.filter(e => e.detail?.toLowerCase().includes(searchQuery.value.toLowerCase()))
            }

            customersOpinions.value = await Promise.all(backData?.map(e => {
                e.date = formatIsoDate(e.date)
                if (e.detail?.length > 100) {
                    e.croppedDetail = e.detail.substring(0, 100) + '...'
                }
                const textColor =
                    e.feedback === 'positive' ? 'success'
                    : e.feedback === 'negative' ? 'danger'
                    : 'warning'
                    e.textColor = textColor
                return e
            }))
            totalOpinions.value = customersOpinions.value.length

            parseDataForExcel(customersOpinions.value)
        } catch (error) {
            console.log(error)
            showErrorMessage(i18n.t('message.err_opinions_list'))
        } finally {
            if (!searchValue || searchValue === searchQuery.value) isLoading.value = false
        }
    }

    const parseDate = (dateRange) => {
        const objDate = new Date(dateRange[2], dateRange[default_language === "en" ? 0 : 1] - 1, dateRange[default_language === "en" ? 1 : 0])
        const year = objDate.getFullYear()
        const month = objDate.getMonth()
        const day = objDate.getDate()
        const date = new Date(year, month, day)
        return date
    }  

    const parseDataForExcel = (customersOpinions) => {
        const parseCustomersOpinions = customersOpinions.map(o => {
            const { location, date, feedback, detail } = o
            return { location: location.location, date, feedback, detail }
        });
        dataExcel.value = parseCustomersOpinions
    }

    // Fields to download the excel
    const customerOpinionsFields = ref({
        [i18n.t('message.tableHeader.location')]: "location",
        [i18n.t('message.tableHeader.date')]: "date",
        [i18n.t('message.tableHeader.feedback')]: "feedback",
        [i18n.t('message.tableHeader.detail')]: "detail",
    })

    const searchInDetail = searchValue => {
        isLoading.value = true
        listCustomersOpinions('list', dateRange.value, selectedLocations.value, searchValue)
    }

    const showingMessage = computed(() => {
        const total = totalOpinions.value
        const from = ((currentPage.value - 1) * perPage.value) + 1
        const to = Math.min(currentPage.value * perPage.value, total)
        return i18n.tc('message.paginationText', 0, { from, to, total })
    })
    
    const datePickerConfig = ref({
        mode: "range",
        dateFormat: default_language === "en" ? "m-d-Y" : "d-m-Y",
    })
    
    const handleView = (id) => {
        opinionId.value = opinionId.value === id ? null : id
    }

    const deleteCustomerOpinion = async (id) => {
        isLoading.value = true

        const query = { _id: ObjectId(id) }
        const action = { $set: { deleted: true } }

        try {
            await updateItem({ collection, query, action })
    
            showSuccessMessage(i18n.t('message.customerOpinionRemoved'))
            listCustomersOpinions('list', dateRange.value, selectedLocations.value)
        } catch (error) {
            console.log(error)
            showErrorMessage(i18n.t('message.opinion_remove_error'))
        } finally {
            isLoading.value = false
        }
    }

    onMounted(() => {
        getLocationsForDropDown()
    })


    watch([dateRange, selectedLocations, selectedOpinion], () => {
        listCustomersOpinions('list', dateRange.value, selectedLocations.value)
    },{ deep: true })
    
return {
        isOnline,
        isLoading,
        showingMessage,
        locations,
        searchQuery,
        customersOpinions,
        tableColumns,
        sortBy,
        isSortDirDesc,
        perPage,
        totalOpinions,
        currentPage,
        dateRange,
        datePickerConfig,
        selectedLocations,
        listCustomersOpinions,
        router,
        deleteCustomerOpinion,
        searchInDetail,
        handleView,
        opinionId,
        clearFilters,
        selectedOpinion,
        translatedOpinionOptions,
        dataExcel,
        customerOpinionsFields,
        role
    }

}