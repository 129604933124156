<template>
  <b-card>
    <b-row >

      <!-- Locations -->
      <b-col md class="col-md">
        <small>{{ $t('label.Locations')  }}</small>
        <b-form-group style="margin-top: .2rem !important" :label-for="$t('label.location')">
          <v-select
            id="location"
            :reduce="(locations) => locations.value"
            :options="locations"
            label="title"
            :placeholder="$t('placeholder.locations')"
            v-model="selectedLocations"
          >
          </v-select>
        </b-form-group>
      </b-col>

      <!-- Date range -->
      <b-col md class="col-md">
        <small>{{ $t('message.tableHeader.dateRange')  }}</small>
        <b-form-group style="margin-top: .2rem !important" label-for="v-date">
          <flat-pickr
            v-model="dateRange"
            class="form-control"
            :config="datePickerConfig"
            :placeholder="$t('placeholder.selectDateRange')"
          />
        </b-form-group>
      </b-col>

      <!-- Opinion -->
      <b-col md class="col-md">
        <small>{{ $t('message.tableHeader.feedback')  }}</small>
        <b-form-group style="margin-top: .2rem !important" :label-for="$t('message.tableHeader.feedback')">
          <!-- :options="[$t('label.positive'), $t('label.negative'), $t('label.neutral')]" -->
          <v-select
            id="feedback"
            :placeholder="$t('placeholder.feedback')"
            :options="translatedOpinionOptions"
            v-model="selectedOpinion"
          >
          </v-select>
        </b-form-group>
      </b-col>
   
      <!-- Search communication -->
      <b-col md class="col-md">
        <small>{{ $t('message.searchInDetail')  }}</small>
        <b-form-group style="margin-top: .2rem !important">
          <b-form-input
            :placeholder="$t('message.search')"
            type="search"
            class="d-inline-block"
            v-model="searchQuery"
            @input="searchInDetail"
          />
        </b-form-group>
      </b-col>

      <!-- Button to clear filter -->
      <b-col md="auto" class="col-md-auto d-flex align-items-center justify-content-end">
        <b-button
          variant="outline-secondary"
          style=" opacity: 0.7; margin-top: .4rem !important"
          @click="clearFilters"
        >
          <feather-icon
            icon="TrashIcon"
            class="mr-50"
          />
          {{ $t('clear_filters') }}
        </b-button>
      </b-col>

      <!-- List customersOpinions -->
      <b-col cols="12" class="mt-2">
        <b-table
          :empty-text="isLoading ? $t('message.loading') : $t('message.no_records')"
          show-empty=""
          :items="paginatedCustomersOpinions"
          hover
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(location)="data">
            <div >{{ data.item.location.location }}</div>
          </template>

          <template #cell(feedback)="data">
            <div style="font-weight: 700" :class="`text-${data.item.textColor}`">{{ $t(`label.${data.item.feedback}`) }}</div>
          </template>


          <template #cell(detail)="data">
            <div v-if="opinionId === data.item._id" v-html="data.item.detail"></div>
            <div v-else v-html="data.item.croppedDetail ? data.item.croppedDetail : data.item.detail"></div>
          </template>
          
          <template #cell(actions)="data">
            <div class="flex flex-column">
              <DeleteButton v-if="role === 'consultant'" v-b-modal="`modal ${data.item._id}`"/>
              <template v-if="data.item.croppedDetail">
                <ViewButton :i18nHoverVariable="'message.showFullDetail'" v-if="opinionId !== data.item._id" variant="flat-success" @clicked="handleView(data.item._id)"/>
                <XButton v-else @clicked="handleView(data.item._id)"/>
              </template>
              <b-modal
                :id="`modal ${data.item._id}`"
                centered
                no-close-on-backdrop
                modal-class="modal-danger"
                ok-variant="danger"
                cancel-variant="outline-secondary"
                :title="$t('message.confirm_action')"
                :ok-title="$t('message.delete')"
                :cancel-title="$t('message.cancel')"
                @ok="deleteCustomerOpinion(data.item._id)"
              > {{ $t('message.confirm_delete_customerOpinion') }}
              </b-modal>
            </div>
          </template>
        </b-table>
      </b-col>

      <!-- Pagination -->
      <b-col
        v-if="isOnline && customersOpinions.length > 0"
        cols="12"
        class="d-flex justify-content-between flex-wrap"
      >
        <div class="mb-1">
          <b-form-group class="mb-0" >
            <label class="d-inline-block text-sm-left mr-50">{{ $t('message.per_page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="[5,10,15,20,25,30,40,50,100]"
              class="w-50"
            />
          </b-form-group>
        </div>

        <div class="mb-1">
          <span class="text-muted">{{ showingMessage }}</span>
        </div>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalOpinions"
          :per-page="perPage"
          first-number=""
          last-number=""
          prev-class="prev-item"
          next-class="next-item"
        />
      </b-col>

    </b-row>
    <!-- Download xlsx -->
    <b-button v-if="!isLoading" class="mr-4 my-1" :disabled="!paginatedCustomersOpinions.length">
      <json-excel :data="dataExcel" :fields="customerOpinionsFields">
        {{ $t('download_xlsx') }}
      </json-excel>
    </b-button>
  </b-card>
</template>
    
<script>
import vSelect from 'vue-select'
import flatPickr from "vue-flatpickr-component"
import { BPagination, BTable, BModal, VBModal } from 'bootstrap-vue'
import useCustomersOpinions from './useCustomersOpinions'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import XButton from '@/views/components/Shared/Buttons/XButton.vue'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import TrashButton from '@/views/components/Shared/Buttons/TrashButton'
import Ripple from 'vue-ripple-directive'
import { onMounted, computed } from '@vue/composition-api'
import JsonExcel from "vue-json-excel"


export default {
  components: {
    vSelect,
    flatPickr,
    BTable,
    BPagination,
    BModal,
    ViewButton,
    EditButton,
    DeleteButton,
    XButton,
    TrashButton,
    JsonExcel
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  setup() {
    const {
      isOnline,
      searchQuery,
      customersOpinions,
      tableColumns,
      sortBy,
      isSortDirDesc,
      isLoading,
      perPage,
      showingMessage,
      currentPage,
      deleteCustomerOpinion,
      listCustomersOpinions,
      searchInDetail,
      totalOpinions,
      locations,
      selectedLocations,
      datePickerConfig,
      dateRange,
      handleView,
      opinionId,
      clearFilters,
      selectedOpinion,
      translatedOpinionOptions,
      dataExcel,
      customerOpinionsFields,
      role
    } = useCustomersOpinions()

    onMounted(() => {
        listCustomersOpinions('list', dateRange.value)
    })
    
    const paginatedCustomersOpinions = computed(() => {
      const start = (currentPage.value - 1) * perPage.value
      const end = start + perPage.value
      return customersOpinions.value.slice(start, end)
    })

    return {
      isOnline,
      searchQuery,
      customersOpinions,
      tableColumns,
      sortBy,
      isSortDirDesc,
      isLoading,
      perPage,
      showingMessage,
      currentPage,
      deleteCustomerOpinion,
      searchInDetail,
      totalOpinions,
      paginatedCustomersOpinions,
      locations,
      selectedLocations,
      datePickerConfig,
      dateRange,
      handleView,
      opinionId,
      clearFilters,
      selectedOpinion,
      translatedOpinionOptions,
      dataExcel,
      customerOpinionsFields,
      role
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>